<template>
  <v-container
    id="Homepage"
    fluid
    tag="section"
  >
    <v-row
      justify="center"
      style="pa-5"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <base-material-card
          title="Power Market Tool"
          height="100%"
          color="#454545"
        >
          <!-- Fills in the information below the avatar icon. pa-0 removes the padding. -->
          <v-card-text
            class="text-center pa-0"
          >
            <font
              size="4"
            >
              The Power Market (name pending) tool is a new tool developed for simplified market analysis of power systems.
              This tool was developed by Zeyu Mao and Wei Trinh at Texas A&M University, for ECEN 460. This tool boasts a few features, with new features undergoing development.
              Interested in trying this tool out for yourself? Visit
              <a
                href="http://people.tamu.edu/~weit1/"
                target="_blank"
              >
                http://people.tamu.edu/~weit1/
              </a>
              to try it out! Below are some instructions: <br>
            </font>
            <p>
              <br>
              <a
                href="/pmclient.pdf"
                target="_blank"
              >
                <font size="4.5">
                  Client Instructions
                </font>
              </a>
            </p>
            <p>
              <a
                href="/pmmarket.pdf"
                target="_blank"
              >
                <font size="4.5">
                  Market Instructions
                </font>
              </a>
            </p>
          </v-card-text>
          <base-material-card
            title="Current Features"
            color="#454545"
            outlined
          >
            <!-- Fills in the information below the avatar icon. pa-0 removes the padding. -->
            <v-card-text
              class="pa-0"
            >
              <font
                size="4"
              >
                <br>
                • Each market is given a unique ID number.
                This means you can have multiple markets running with multiple ID numbers, without worry of intereference between the participants of different markets. <br> <br>
                • Markets are dynamically scalable, meaning you can have any number of participants, and the load that must be served will scale with the
                number of participants. <br> <br>
                • Last Accepted Offer/Pay As Offered Markets are readily available. This allosws users to test different markets, and observe different behaviors. <br> <br>
                • Visualizations for bids, profits, etc. Want to see how your bids evolve over time? Or how much money you've made each period?
                There are visualizations that exist which allow you to plan your bidding based on your historical results. <br> <br>
                • Load Profiles: Want to serve a larger amount of load than the default load profile?
                You can upload your own load profile to test how different loads will affect your market participants behaviors.
              </font>
            </v-card-text>
            <v-card
              flat
              justify="center"
            >
              <v-img
                class="mx-auto"
                src="https://i.imgur.com/mCVNE61.png"
                width="60%"
              />
              <v-card-text
                class="text-center"
              >
                Examples of Visualizations that track your bids and the clearing price in the Power Market Tool.
              </v-card-text> <br> <br>
              <v-img
                class="mx-auto"
                src="https://i.imgur.com/bCQgoET.png"
                width="60%"
              />
              <v-card-text
                class="text-center"
              >
                Examples of Visualizations that track your per-period and total profits in the Power Market Tool.
              </v-card-text>
            </v-card>
          </base-material-card>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Homepage',
  }
</script>
